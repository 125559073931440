import './loader.css'

const PageLoader = () => {

    return (
        <div className="page-loader-container">
            <div className="page-loader"></div>
        </div>
    )
}

export default PageLoader;